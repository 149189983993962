import ApiService from "../services/api.service";

const prefix = "cms";
const storeConfigNoti = prefix + "/config-notification";
const urlConfigSegment = prefix + "/segment";
const urlConfigMerchant = prefix + "/merchant";
const urlCard = prefix + "/cards";
const urlReconcileCashback = "/reconcile/cashback";
const urlPromotion = "/promotion";
const urlReconcile = "reconcile/";
const urlAutoPayment = "/auto-payment";
const urlDailyBalance = "/daily-balance";
const urlDailyPoint = "/daily-point";
const urlExportDailyPoint = "/daily-point/export";
const urlFee = "/fee";
const urlRouteApp = "/route-app";
const urlDeeplink = "/config-deeplink";
const urlMessageTemplate = "/message-template";
const urlServiceApp = "/service-app";
const urlBounceMail = "/bounce-mail";
const urlMailMerchant = "/mail-merchant";
const urlSchedule = "/schedule";
const urlAdmin = "/admin";
const urlDashboard = "/dashboard";
const marketing = "/marketing";
const segmentAction = "/segment/action";
const account = "/account";
const bankMaintenance = "/cms/bank";
const bankProvider = "/cms/bank";
const product = prefix + "/product";
const requestLockAccount = "account/request-lock";
const urlRiskPoint = prefix + "/config-risk-point";

export default {
  listAll(params) {
    return ApiService.get(storeConfigNoti + "/listAll", "", params);
  },
  storeConfig(params) {
    return ApiService.post(storeConfigNoti + "/store", params);
  },
  updateConfig(params) {
    return ApiService.post(storeConfigNoti + "/update", params);
  },
  deleteConfig(params) {
    return ApiService.post(storeConfigNoti + "/delete", params);
  },

  // cms segment
  listAllSegment(params) {
    return ApiService.get(urlConfigSegment + "/listAll", "", params);
  },
  listSegmentAvailable(params) {
    return ApiService.get(urlConfigSegment + "/listAvailable", "", params);
  },
  storeSegment(params) {
    return ApiService.post(urlConfigSegment + "/store", params);
  },
  storeSegmentByFile(params) {
    return ApiService.post(urlConfigSegment + "/store-by-file", params);
  },
  updateUserSegment(params) {
    return ApiService.post(urlConfigSegment + "/changeUser", params);
  },
  updateSegment(params) {
    return ApiService.post(urlConfigSegment + "/update", params);
  },
  deleteSegment(params) {
    return ApiService.post(urlConfigSegment + "/delete", params);
  },
  listUserSegement(params) {
    return ApiService.get(urlConfigSegment + "/segment-user", "", params);
  },
  addUserSegement(params) {
    return ApiService.post(urlConfigSegment + "/segment-user/store", params);
  },
  deleteUserSegement(params) {
    return ApiService.post(urlConfigSegment + "/segment-user/delete", params);
  },
  getUserGroups() {
    return ApiService.get(urlConfigSegment + "/list-groups");
  },

  //api list info
  listAllBank(params) {
    return ApiService.get(urlConfigSegment + "/list-bank", params);
  },
  listMerchant(params) {
    return ApiService.get(urlConfigSegment + "/list-merchant", params);
  },
  listService(params) {
    return ApiService.get(urlConfigSegment + "/list-service", params);
  },
  listServiceByType(params) {
    return ApiService.get(urlConfigSegment + "/service-by-type", "", params);
  },

  //Cal API Merchant
  listMerchantParent() {
    return ApiService.get(urlConfigMerchant + "/list-parent");
  },
  listMerchantAll(params) {
    return ApiService.get(urlConfigMerchant + "/listAll", "", params);
  },
  storeMerchant(params) {
    return ApiService.post(urlConfigMerchant + "/store", params);
  },
  // Tools retry cards
  listServiceCard(params) {
    return ApiService.get(urlCard + "/service", "", params);
  },
  listProductCard(params) {
    return ApiService.get(urlCard + "/product", "", params);
  },
  retryCard(params) {
    return ApiService.post(urlCard + "/retry", params);
  },
  updateMerchant(params) {
    return ApiService.post(urlConfigMerchant + "/update", params);
  },

  //List reconcile cashback
  listCashback(params) {
    return ApiService.get(urlReconcileCashback, "", params);
  },
  viewCashback(id, params) {
    return ApiService.get(urlReconcileCashback + "/view/" + id, "", params);
  },

  // Reconcile Sacombank
  addFileSacombank(params) {
    return ApiService.post(urlReconcile + "sacombank/upload-file", params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  listSacombank(params) {
    return ApiService.get(urlReconcile + "sacombank", "", params);
  },
  detailSacombank(id, params) {
    return ApiService.get(urlReconcile + "sacombank/view/" + id, "", params);
  },
  // Payoo
  addFileReconcile(params) {
    return ApiService.post(urlReconcile + "upload", params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  listReconcile(params) {
    return ApiService.get("reconcile", "", params);
  },
  detailReconcile(id, params) {
    return ApiService.get(urlReconcile + "view/" + id, "", params);
  },

  // Promotion
  listPromotion(params) {
    return ApiService.get(urlPromotion, "", params);
  },
  viewPromotion(id) {
    return ApiService.get(urlPromotion + "/view/" + id, "");
  },
  createPromotion(params) {
    return ApiService.post(urlPromotion + "/create", params);
  },
  updatePromotion(id, params) {
    return ApiService.post(urlPromotion + "/update/" + id, params);
  },
  removePromotion(id) {
    return ApiService.post(urlPromotion + "/remove/" + id, {});
  },

  // Api đối soát ví
  listShowReconcileWallet(params) {
    return ApiService.get(urlReconcile + "wallet/show-transaction", "", params);
  },
  totalReconcileWallet(params) {
    return ApiService.get(
      urlReconcile + "wallet/show-total-transaction",
      "",
      params
    );
  },
  exportFileReconcileWallet(params) {
    return ApiService.post(urlReconcile + "wallet/export-transaction", params);
  },
  listExportFileReconcileWallet(params) {
    return ApiService.get(urlReconcile + "wallet/list-export", "", params);
  },
  listDataFilter(params) {
    return ApiService.get(urlReconcile + "get-list-card-filter", "", params);
  },
  // Thanh toán tự động
  listAllAutoPayment(params) {
    return ApiService.get(urlAutoPayment + "/listAll", "", params);
  },
  detailAutoPayment(params) {
    return ApiService.get(urlAutoPayment + "/detail", "", params);
  },
  transactionOrderInfo(params) {
    return ApiService.post(urlAutoPayment + "/transaction", params);
  },
  //Balance
  listDailyBalance(params) {
    return ApiService.get(urlDailyBalance, "", params);
  },
  exportDailyBalance(params) {
    return ApiService.get(urlDailyBalance + "/export", "", params);
  },
  listLogActionService(params) {
    return ApiService.get(prefix + "/service-log", "", params);
  },

  // Config fee
  listServiceFee(params) {
    return ApiService.get(urlFee + "/get-list-service", "", params);
  },
  listServiceConfigFee(params) {
    return ApiService.get(urlFee + "/get-list-config", "", params);
  },
  listExtraConfigFee(params) {
    return ApiService.get(urlFee + "/get-config-extra", "", params);
  },
  storeFee(params) {
    return ApiService.post(urlFee + "/store", params);
  },
  acceptFee(params) {
    return ApiService.post(urlFee + "/accept", params);
  },
  viewFee(params) {
    return ApiService.get(urlFee + "/view", "", params);
  },
  listAllFee(params) {
    return ApiService.get(urlFee, "", params);
  },
  updateFee(params) {
    return ApiService.post(urlFee + "/update", params);
  },

  // quản trị hệ thống
  listAllPermission(params) {
    return ApiService.get(urlAdmin + "/permissions", "", params);
  },
  deletePermission(id) {
    return ApiService.delete(urlAdmin + "/permissions/" + id);
  },
  updatePermission(id, params) {
    return ApiService.put(urlAdmin + "/permissions/" + id, params);
  },
  getGroupPermission() {
    return ApiService.get(urlAdmin + "/permission/group");
  },
  createRole(params) {
    return ApiService.post(urlAdmin + "/roles", params);
  },
  updateRole(id, params) {
    return ApiService.put(urlAdmin + "/roles/" + id, params);
  },
  detailRole(id) {
    return ApiService.get(urlAdmin + "/roles/" + id);
  },
  getAllRoles(params) {
    return ApiService.get(urlAdmin + "/roles", "", params);
  },
  deleteRoles(id) {
    return ApiService.delete(urlAdmin + "/roles/" + id);
  },
  listAllRoles() {
    return ApiService.get(urlAdmin + "/roles/listAll");
  },
  listUser(parmas) {
    return ApiService.get(urlAdmin + "/users/", "", parmas);
  },
  addUser(params)
  {
    return ApiService.post(urlAdmin + "/users", params);
  },
  detailUser(id) {
    return ApiService.get(urlAdmin + "/users/" + id);
  },
  updateUser(id, params) {
    return ApiService.put(urlAdmin + "/users/" + id, params);
  },
  deleteUser(id) {
    return ApiService.delete(urlAdmin + "/users/" + id);
  },

  // Point
  listDailyPoint(params) {
    return ApiService.get(urlDailyPoint, "", params);
  },
  listDailyExport(params) {
    return ApiService.get(urlExportDailyPoint, "", params);
  },

  //route App
  listRouteApp(params) {
    return ApiService.get(urlRouteApp + "/list", "", params);
  },
  storeRouteApp(params) {
    return ApiService.get(urlRouteApp + "/store", "", params);
  },
  updateRouteApp(id, params) {
    return ApiService.get(urlRouteApp + "/update/" + id, "", params);
  },
  detailRouteApp(id) {
    return ApiService.get(urlRouteApp + "/view/" + id, "");
  },
  deleteRouteApp(id) {
    return ApiService.get(urlRouteApp + "/delete/" + id, "");
  },
  //deeplink
  listDeeplink(params) {
    return ApiService.get(urlDeeplink + "/list", "", params);
  },
  storeDeeplink(params) {
    return ApiService.get(urlDeeplink + "/store", "", params);
  },
  updateDeeplink(id, params) {
    return ApiService.post(urlDeeplink + "/update/" + id, params );
  },
  detailDeeplink(id) {
    return ApiService.get(urlDeeplink + "/view/" + id, "");
  },
  deleteDeeplink(id) {
    return ApiService.get(urlDeeplink + "/delete/" + id, "");
  },
  // Dashboard
  listDashboard(params)
  {
    return ApiService.get(urlDashboard + "/marketing", "", params);
  },
  listServices()
  {
    return ApiService.get(urlDashboard + "/services", "");
  },
  exportData(params) {
    return ApiService.get(urlDashboard + "/marketing/export", "", params);
  },
  // hẹn giờ
  listSchedule(params) {
    return ApiService.get(urlSchedule + "/list", "", params);
  },
  storeSchedule(params) {
    return ApiService.post(urlSchedule + "/store", params);
  },
  updateSchedule(id, params) {
    return ApiService.post(urlSchedule + "/update/" + id, params);
  },
  deleteSchedule(id) {
    return ApiService.post(urlSchedule + "/delete/" + id, "");
  },
  acceptSchedule(id, params) {
    return ApiService.post(urlSchedule + "/accept/" + id, params);
  },
  detailSchedule(id) {
    return ApiService.get(urlSchedule, "view/" + id);
  },
  //route App
  listTemplate(params) {
    return ApiService.get(urlMessageTemplate + "/list", "", params);
  },
  storeTemplate(params) {
    return ApiService.post(urlMessageTemplate + "/store", params);
  },
  updateTemplate(id, params) {
    return ApiService.post(urlMessageTemplate + "/update/" + id, params);
  },
  detailTemplate(id) {
    return ApiService.get(urlMessageTemplate + "/view/" + id, "");
  },
  deleteTemplate(id) {
    return ApiService.post(urlMessageTemplate + "/delete/" + id, "");
  },
  //appService
  listServiceApp(params) {
    return ApiService.get(urlServiceApp + "/list", "", params);
  },
  listServiceTypes(params) {
    return ApiService.get(urlServiceApp + "/list-types", "", params);
  },
  storeServiceApp(params) {
    return ApiService.post(urlServiceApp + "/store", params);
  },
  updateServiceApp(id, params) {
    return ApiService.post(urlServiceApp + "/update/" + id, params);
  },
  detailServiceApp(id) {
    return ApiService.get(urlServiceApp + "/view/" + id, "");
  },
  deleteServiceApp(id) {
    return ApiService.post(urlServiceApp + "/delete/" + id, "");
  },
  //BounceMail
  listBounceMail(params) {
    return ApiService.get(urlBounceMail + "/list", "", params);
  },
  storeBounceMail(params) {
    return ApiService.post(urlBounceMail + "/store", params);
  },
  detailBounceMail(id) {
    return ApiService.get(urlBounceMail + "/view/" + id, "");
  },
  deleteBounceMail(id) {
    return ApiService.post(urlBounceMail + "/remove/" + id, "");
  },
  //Mail Merchant
  listMailMerchant(params) {
    return ApiService.get(urlMailMerchant + "/list", "", params);
  },
  storeMailMerchant(params) {
    return ApiService.post(urlMailMerchant + "/store", params);
  },
  updateMailMerchant(id,params) {
    return ApiService.post(urlMailMerchant + "/update/" + id, params);
  },
  detailMailMerchant(id) {
    return ApiService.get(urlMailMerchant + "/view/" + id, "");
  },
  deleteMailMerchant(id) {
    return ApiService.post(urlMailMerchant + "/delete/" + id, "");
  },
  //Marketing
  listVoucher(params) {
    return ApiService.get(marketing, "report-voucher", params);
  },
  exportVoucher(params) {
    return ApiService.get(marketing, "report-voucher/export", params);
  },
  userTransactions(params) {
    return ApiService.post(marketing+'/user-transactions',  params);
  },
  exportUserTransactions(params) {
    return ApiService.post(marketing+ "/user-transactions/export", params);
  },
  services() {
    return ApiService.get(marketing, "services");
  },

  //Segment Action
  segmentAction(params) {
    return ApiService.get(segmentAction, "", params);
  },
  segmentActionUsers(id, params) {
    return ApiService.get(segmentAction, "list-user/" + id, params);
  },
  createSegmentAction(params) {
    return ApiService.post(segmentAction + "/create", params);
  },
  approveAction(params) {
    return ApiService.post(segmentAction + "/approve", params);
  },
  rejectAction(params) {
    return ApiService.post(segmentAction + "/reject", params);
  },
  importFilePhone(params) {
    return ApiService.post(segmentAction+  "/import", params);
  },
  usersLock(params) {
    return ApiService.get(account, "list-account-lock", params);
  },
  unLockUser(params) {
    return ApiService.post(account + "/unlock",  params);
  },
  // Bank Maintenance
  listBank(params) {
    return ApiService.get(bankProvider, "", params);
  },
  listMaintenance(params) {
    return ApiService.get(bankMaintenance, "maintenance", params);
  },
  getDetailBankMaintenance(id) {
    return ApiService.get(bankMaintenance, "maintenance/detail/" + id);
  },
  createBankMaintenance(params) {
    return ApiService.post(bankMaintenance + "/maintenance/store", params);
  },
  removeBankMaintenance(id) {
    return ApiService.get(bankMaintenance + "/maintenance/delete", id);
  },
  updateBankMaintenance(id, params) {
    return ApiService.post(bankMaintenance + "/maintenance/update/" + id, params);
  },
  // Bank monitor
  listBankMonitor(params) {
    return ApiService.get(bankMaintenance, "monitor", params);
  },
  updateBankMonitor(id, params) {
    return ApiService.post(bankMaintenance + "/monitor/update/" + id, params);
  },
  // Product
  listAllProducts(params) {
    return ApiService.get(product, "list-all", params);
  },
  //Request lock account
  listRequestLockAccount(params) {
    return ApiService.get(requestLockAccount, "", params);
  },
  detailRequestLockAccount(id) {
    return ApiService.get(requestLockAccount, "detail/" + id);
  },
  approveRequestLockAccount(params) {
    return ApiService.post(requestLockAccount + "/approve", params);
  },
  rejectRequestLockAccount(params) {
    return ApiService.post(requestLockAccount + "/reject", params);
  },
  // Risk Point
  getRiskPoint(){
    return ApiService.get(urlRiskPoint, "");
  },
  checkRiskPoint(params) {
    return ApiService.post(urlRiskPoint + "/check-risk-point", params)
  },
  checkRiskCriteria(params) {
    return ApiService.post(urlRiskPoint + "/check-risk-criteria", params)
  },
  storeRiskPoint(params) {
    return ApiService.post(urlRiskPoint + "/store", params)
  },
  updateRiskPoint( params) {
    return ApiService.post(urlRiskPoint + "/update" , params)
  }
};
